import './Home.css';
import Home from './Home'
import Silver from './Silver'
import Ceramics from './Ceramics'
import Graphics from './Graphics'
import Contact from './Contact'

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/silver" component={Silver} />
        <Route path="/ceramics" component={Ceramics} />
        <Route path="/graphics" component={Graphics} />
        <Route path="/contact" component={Contact} />
        <Route component={Home} />
      </Switch>
    </Router>
  );
}

export default App;