import React, { useState, useCallback } from "react";
import Nav from './Nav';

import Carousel, { Modal, ModalGateway } from "react-images";

import dimak from "./img/works/ceramica/dimak.jpg"
import teynik_bajaknerov from "./img/works/ceramica/teynik_bajaknerov.jpg"

function Ceramics() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const images = [
        { src: dimak, comment: "dimak" },
        { src: teynik_bajaknerov, comment: "teynik_bajaknerov" },
    ];


    const openImageViewer = useCallback(index => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <>
            <Nav />
            <div className='gallery ceramics'>
                <div className="gallery-wrap">
                    {images.map((el, index) => (
                        <div className='img-cont'>
                            <img
                                className={`img${index}`}
                                src={el.src}
                                onClick={() => openImageViewer(index)}
                                key={index}
                                style={{ margin: "2px" }}
                                alt="Ceramics"
                            />
                        </div>
                    ))}
                </div>

                {isViewerOpen ? (
                    <ModalGateway>
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={images.map(x => ({ src: x.src, caption: x.comment }))}
                            />
                        </Modal>
                    </ModalGateway>
                ) : null}
            </div>
        </>
    );
}
export default Ceramics;