import React, { useState, useCallback } from "react";
import Nav from './Nav';

import Carousel, { Modal, ModalGateway } from "react-images";

import aghjik from "./img/works/silver/aghjkaerjankutyun.jpg"
import eghnik from "./img/works/silver/eghnik.jpg"
import kacheli from "./img/works/silver/kacheli.jpg"
import korablik from "./img/works/silver/korablik.jpg"
import matador from "./img/works/silver/matador.jpg"
import navak from "./img/works/silver/navak.jpg"
import petitprinvce from "./img/works/silver/petitprince.jpg"
import polet from "./img/works/silver/polet.jpg"
import strast from "./img/works/silver/strast.jpg"
import violin from "./img/works/silver/violin.jpg"

function Silver() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [
    { src: kacheli, comment: "kacheli" },
    { src: strast, comment: "strast" },
    { src: violin, comment: "violin" },
    { src: petitprinvce, comment: "petitprinvce" },
    { src: eghnik, comment: "eghnik" },
    { src: aghjik, comment: "aghjik" },
    { src: korablik, comment: "korablik" },
    { src: polet, comment: "polet" },
    { src: matador, comment: "matador" },
    { src: navak, comment: "navak" }
  ];


  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <Nav />
      <div className='gallery silver'>
        <div className="gallery-wrap">
          {images.map((el, index) => (
            <div className='img-cont'>
              <img
                className={`img${index}`}
                src={el.src}
                onClick={() => openImageViewer(index)}
                key={index}
                style={{ margin: "2px" }}
                alt="Silver"
              />
            </div>
          ))}
        </div>

        {isViewerOpen ? (
          <ModalGateway>
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={images.map(x => ({ src: x.src, caption: x.comment }))}
              />
            </Modal>
          </ModalGateway>
        ) : null}
      </div>
    </>
  );
}
export default Silver;