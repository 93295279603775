import React from 'react';
import Marine from './img/Marine.jpg'

function Jumbotron() {
    return (
        <div className='jumbotron'>
            <img src={Marine} alt='Marine' />
            <p>All roads lead to people.</p>
        </div>
    );
}

export default Jumbotron;