import React from 'react';
import { Link } from 'react-router-dom';

function Nav() {
    return (
        <nav>
            <Link id='silver' to='/silver'><div className='silver'></div></Link>
            <Link id='keramika' to='/ceramics'><div className='keramika'></div></Link>
            <Link id='grafika' to='/graphics'><div className='grafika'></div></Link>
            <Link id='contact' to='/contact'><div className='contact'></div></Link>
        </nav>
    );
}

export default Nav;