import { FormBuilder, FieldGroup, FieldControl, Validators } from "react-reactive-form";
import Nav from './Nav';

const TextInput = ({ handler, touched, hasError, meta }) => (
    <div className="input-form">
        <label htmlFor="email" className={(touched && hasError("required")) || (touched && hasError("email")) ? "error" : ""}>Email</label>
        <input id="email" type="email" placeholder={`Enter ${meta.label}`} {...handler()} />
        <span className="errors">
            {touched
                && hasError("required")
                && `${meta.label} is required`}
        </span>
        <span className="errors">
            {touched
                && hasError("email")
                && `Enter valid email address please`}
        </span>
    </div>
);

const TextArea = ({ handler, touched, hasError, meta }) => (
    <div className="input-form">
        <label htmlFor="email" className={touched && hasError("required") ? "error" : ""}>Message</label>
        <textarea placeholder={`Enter ${meta.label}`} {...handler()} />
        <span className="errors">
            {touched
                && hasError("required")
                && `Enter Message please`}
        </span>
    </div>
);

function Contact() {
    let contactForm = FormBuilder.group({
        email: ["", [Validators.email, Validators.required]],
        message: ["", Validators.required],
    });

    let handleSubmit = async (e) => {
        e.preventDefault();
        alert('Success!');
        try {
            await fetch('/contactEmail', { method: 'POST', body: JSON.stringify(contactForm.value), headers: { 'Content-Type': 'application/json' } });
            contactForm.reset();
        } catch (e) {
            console.log(e.message);
        }
    }

    return (
        <main className='contact'>
            <Nav />
            <FieldGroup
                control={contactForm}
                render={({ get, invalid }) => (
                    <form onSubmit={handleSubmit}>
                        <FieldControl
                            name="email"
                            render={TextInput}
                            meta={{ label: "Email" }}
                        />
                        <FieldControl
                            name="message"
                            render={TextArea}
                            meta={{ label: "Message" }}
                        />
                        <div className="input-form"><button type="submit" disabled={invalid}>Submit</button></div>
                    </form>
                )}
            />
        </main>
    );
}

export default Contact;