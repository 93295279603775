import React, { useState, useCallback } from "react";
import Nav from './Nav';

import Carousel, { Modal, ModalGateway } from "react-images";

import Apatiko_abulia_ from "./img/works/graphics/Apatiko_abulia_.gif"
import Chgitem from "./img/works/graphics/Chgitem.gif"
import chgitem_inch from "./img/works/graphics/chgitem_inch.gif"
import maninlove from "./img/works/graphics/maninlove.jpg"
import molorvats_kap from "./img/works/graphics/molorvats_kap.gif"
import Par from "./img/works/graphics/Par.gif"
import Pechal_2 from "./img/works/graphics/Pechal_2.jpg"
import Sarer from "./img/works/graphics/Sarer.gif"
import Son from "./img/works/graphics/Son.gif"
import son_vo_sne from "./img/works/graphics/son_vo_sne.jpg"
import Tango from "./img/works/graphics/Tango.gif"
import Varder from "./img/works/graphics/Varder.jpg"

function Graphics() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const images = [
        { src: Apatiko_abulia_, comment: "" },
        { src: Chgitem, comment: "Chgitem" },
        { src: chgitem_inch, comment: "chgitem_inch" },
        { src: maninlove, comment: "maninlove" },
        { src: molorvats_kap, comment: "molorvats_kap" },
        { src: Par, comment: "Par" },
        { src: Pechal_2, comment: "Pechal_2" },
        { src: Sarer, comment: "Sarer" },
        { src: Son, comment: "Son" },
        { src: son_vo_sne, comment: "son_vo_sne" },
        { src: Tango, comment: "Tango" },
        { src: Varder, comment: "Varder" },
    ];


    const openImageViewer = useCallback(index => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <>
            <Nav />
            <div className='gallery graphics'>
                <div className="gallery-wrap">
                    {images.map((el, index) => (
                        <div className='img-cont'>
                            <img
                                className={`img${index}`}
                                src={el.src}
                                onClick={() => openImageViewer(index)}
                                key={index}
                                style={{ margin: "2px" }}
                                alt="Ceramics"
                            />
                        </div>
                    ))}
                </div>

                {isViewerOpen ? (
                    <ModalGateway>
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={images.map(x => ({ src: x.src, caption: x.comment }))}
                            />
                        </Modal>
                    </ModalGateway>
                ) : null}
            </div>
        </>
    );
}
export default Graphics;