import Jumbotron from './Jumbotron'
import Nav from './HomeNav'

function Home() {
    return (
        <main className='homepage'>
            <Jumbotron />
            <Nav />
        </main>
    );
}

export default Home;